import React from "react";
import Footer from "../../../../components/Footer";
import Header from "../../../../components/Header";

import img1 from './Imagem1.jpg'
import img2 from './Imagem2.jpg'
import img3 from './Imagem3.png'
import img4 from './Imagem4.png'
import img5 from './Imagem5.png'


// TODO: colocar as imagens certinhas e as legendas nesse post
// TODO: fazer o outro post certinho do webinar, subir netlify e enviar msg pro fracassi

export default function Page201120202() {
  return (
    <>
      <Header />
      <section id="event-slides-area" className="section-padding">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="section-title-header text-center">
                <h1
                  id="area"
                  class="section-title wow fadeInUp"
                  data-wow-delay="0.2s"
                >
                  Determinação Rápida de Pureza de Óleos de Copaíba
                  por Espectrômetro Portátil NIR e PLS R
                </h1>
              </div>
            </div>

            <img
              class="img-fluid mx-auto col-12"
              src={img1}
              alt=""
            />
            <p class="normaltext text-justify col-12">Figura 1. (a) árvore da espécie Copaifera langsdorffii.</p>
          </div>

          <div className="row mt-5">
            <div className="col">
              <div class="wow" data-wow-delay="0.3s">
                <p class="normaltext text-justify col-12">
                  O óleo de copaíba (OC) é um produto florestal geralmente extraído
                  de forma artesanal diretamente das árvores por pequenos produtores
                  e cooperativas (Figura 1). Esse óleo é muito utilizado na medicina
                  popular por sua potente ação anti-inflamatória e cicatrizante,
                  sendo ainda matéria prima de diversos cosméticos. Seu uso
                  medicinal implica em um rigoroso controle de qualidade, no
                  conhecimento de suas propriedades e estabilidade. Contudo, devido
                  à sua grande variabilidade natural (Figura 2), os métodos de
                  análise já descritos na literatura ainda não atendem adequadamente
                  à demanda desse setor, principalmente de pequenas cooperativas que
                  não dispõem de equipamentos de custo elevado.
                </p>
                <p class="normaltext text-justify col-12 mt-3">
                  Nesse trabalho foi proposto um método para a determinação da
                  pureza de óleos de copaíba (Copaifera langsdorffii) e
                  diferenciação entre amostras autênticas e adulteradas utilizando
                  um espectrômetro portátil operando na região do infravermelho
                  próximo (NIR) e análise quimiométrica/multivariada (Figura 3). A
                  exatidão e precisão média do método na avaliação de sua pureza foi
                  de aproximadamente 1,5 %(v/v) (Figura 4). Foi possível ainda
                  diferenciar amostras de óleos de copaíbas autênticas de amostras
                  adulteradas com 5 tipos de óleos vegetais comestíveis em diversos
                  níveis de adulteração (5 a 50%(v/v) de adulteração). O método
                  proposto pode ser considerado prático, rápido, eficiente,
                  acessível para pequenos produtores e cooperativas e com
                  possibilidade de aplicação in situ em cooperativas ou por órgãos
                  de fiscalização.
                </p>
              </div>
            </div>
          </div>
          <div className="row mt-5 ">
            <div className="col-6">
              <div className="row justify-content-center">
                <img
                  class="img-fluid mx-auto"
                  src={img2}
                  alt=""
                />
              </div>
              <p class="normaltext text-justify col-12">Figura 2. (b) Extração artesanal do óleo de copaíba.</p>

            </div>
            <div className="col-6">
              <img
                class="img-fluid mx-auto"
                src={img3}
                alt=""
              />
              <p class="normaltext text-justify col-12">Figura 3. Foto de vários óleos de copaíba obtidos de diferentes origens. Diferenças na coloração ilustram a variabilidade que ocorre devido ao solo, proximidade de fonte de água, época de extração, etc.</p>

            </div>
          </div>
          <div className="row mt-5">
            <div className="col-6">
              <img
                class="img-fluid mx-auto"
                src={img4}
                alt=""
              />
              <p class="normaltext text-justify col-12">Figura 4. Resultados experimentais (espectros na região do infravermelho próximo) obtidos para óleos de copaíba puros (OC), óleo de soja (OS) e misturas de óleo de copaíba adulterados com óleo de soja (OCOS). Em destaque os principais comprimentos de onda onde são observadas diferenças nos espectros.</p>
            </div>
            <div className="col-6">
              <img
                class="img-fluid mx-auto"
                src={img5}
                alt=""
              />
              <p class="normaltext text-justify col-12">Figura 5. Comparação entre os valores de referência e estimados pelo método para a análise de amostras de referência (●) e amostras teste ( ).</p>
            </div>
          </div>
        </div>
      </section >
      <Footer />
    </>
  );
}
