import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import ResearchItem from "../../components/ResearchItem";
import { researchersList } from "./researchersList";

export default function Researchers() {
  return (
    <>
    <Header />
      <section id="team" class="section-padding text-center">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="section-title-header text-center">
                <h1 class="section-title wow fadeInUp" data-wow-delay="0.2s">
                  Pesquisadores
                </h1>
              </div>
            </div>
          </div>
          <div class="row">
            {researchersList.map(({ name, imageURL, profileURL }) => {
              return (
                <ResearchItem
                  name={name}
                  imageURL={imageURL}
                  profileURL={profileURL}
                />
              );
            })}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
