import React from "react";
import { Link } from "react-router-dom";

export default function SectionAreas() {
  return (
    <>
      <section id="areas" class="section-padding">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="section-title-header text-center">
                <h1 class="section-title wow fadeInUp" data-wow-delay="0.2s">
                  Conheça as áreas
                </h1>
                <p class="wow fadeInDown" data-wow-delay="0.2s">
                  O INCTBio está organizado e dividido em três áreas:
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 col-md-6 col-lg-4">
              <div class="about-item">
                <img class="img-fluid" src="assets/img/about/img1.jpg" alt="" />
                <div class="about-text">
                  <h3>
                    <a href="areaseparacoes.html">AREA DE SEPARAÇÕES</a>
                  </h3>
                  <Link to='/separacoes' className="btn btn-common btn-rm"
                  >
                    Saiba Mais
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-md-6 col-lg-4">
              <div class="about-item">
                <img class="img-fluid" src="assets/img/about/img2.jpg" alt="" />
                <div class="about-text">
                  <h3>
                    <a href="areaespectro.html">ÁREA DE ESPECTROANALÍTICA</a>
                  </h3>
                  <Link to="/eletroanalitica" className="btn btn-common btn-rm">
                    Saiba mais
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-md-6 col-lg-4">
              <div class="about-item">
                <img class="img-fluid" src="assets/img/about/img3.jpg" alt="" />
                <div class="about-text">
                  <h3>
                    <a href="areabiossensores.html">ÁREA DE BIOSSENSORES</a>
                  </h3>
                  <Link to="/biossensores" className="btn btn-common btn-rm">
                    Saiba mais
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
