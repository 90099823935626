import React from "react";
import { Link } from "react-router-dom";
import Thumb20112020 from "../pages/news/news-thumbs/Thumb20112020";
import Thumb201120202 from "../pages/news/news-thumbs/Thumb201120202";
import Thumb23082020 from "../pages/news/news-thumbs/Thumb23082020";

export default function SectionNews() {
  return (
    <>
      <section id="blog" class="section-padding">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="section-title-header text-center">
                <h1 class="section-title wow fadeInUp" data-wow-delay="0.2s">
                  ÚTIMAS NOVIDADES
                </h1>
                <p class="wow fadeInDown" data-wow-delay="0.2s">
                  Artigos, Publicações e Notícias
                </p>
              </div>
            </div>

            <Thumb20112020 />
            <Thumb201120202 />
            <Thumb23082020 />


            <div class="col-12 text-center">
              <Link to="/noticias" className="btn btn-common">
                Veja todos as publicações
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
