import React, { Fragment } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";

// base pages
import Biosensors from "./areas/Biosensors";
import Electroanalytics from "./areas/Electroanalytics";
import Separations from "./areas/Separations";
import Home from "./home/Home";
import News from "./news/News";
import Researchers from "./researchers/Researchers";

// blog posts page
import Page201120202 from "./news/news-pages/Page201120202/Page201120202";
import Page23082020 from "./news/news-pages/Page23082020/Page23082020";


export default function Routes() {
  return (
    <>
      <Router>
        <Fragment>
          <ScrollToTop />
          <Switch>
            <Route path='/201120202'>
              <Page201120202 />
            </Route>
            <Route path='/23082020'>
              <Page23082020 />
            </Route>
            <Route path="/biossensores">
              <Biosensors />
            </Route>
            <Route path="/eletroanalitica">
              <Electroanalytics />
            </Route>
            <Route path="/separacoes">
              <Separations />
            </Route>
            <Route path="/pesquisadores">
              <Researchers />
            </Route>
            <Route path="/noticias">
              <News />
            </Route>
            <Route path="/" exact>
              <Home />
            </Route>
          </Switch>

        </Fragment>

      </Router>
    </>
  );
}
