import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

export default function Separations() {
  return (
    <>
      <Header />
      <section id="event-slides-area" class="section-padding">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="section-title-header text-center">
                <h1
                  id="area"
                  class="section-title wow fadeInUp"
                  data-wow-delay="0.2s"
                >
                  SOBRE A ÁREA DE SEPARAÇÕES
                </h1>
              </div>
              <div class="wow" data-wow-delay="0.3s">
                <p class="normaltext text-justify col-12">
                  Sistemas biológicos envolvem grande complexidade. Virtualmente
                  todos os tipos de moléculas podem ser encontradas – desde íons
                  inorgânicos pequenos como sódio, potássio, cálcio, cloreto,
                  fosfato, até macromoléculas como proteínas, enzimas, DNA e
                  RNA. Ainda há a presença de diversas classes de compostos,
                  como ácidos orgânicos, lipídeos, cofatores, hormônios, e uma
                  infinidade de metabólitos. Além disso, a associação destas
                  moléculas leva à organização do sistema em estruturas de alta
                  complexidade, como membranas, organelas, células, tecidos,
                  órgãos, etc.
                </p>
                <p class="normaltext text-justify col-12">
                  {" "}
                  Nesse cenário, é imprescindível na maioria dos casos que a
                  complexidade dos sistemas seja reduzida para que a
                  determinação de determinado composto alvo seja conduzida com
                  exatidão. Nesse aspecto, as técnicas de separação são
                  ferramentas poderosas para que a seletividade adequada seja
                  alcançada.
                </p>
                <p class="normaltext text-justify col-12">
                  Separar significa fracionar, com o objetivo de tornar a
                  detecção e quantificação inequívocas. Há várias técnicas
                  empregadas para essa finalidade como, por exemplo, as técnicas
                  clássicas de precipitação seletiva e extração líquido-líquido,
                  e técnicas cromatográficas e eletroforéticas que podem ser
                  automatizadas em equipamentos dedicados.
                </p>
                <p class="normaltext text-justify col-12">
                  A área de separações do INCTBio engloba __ pesquisadores de __
                  Instituições. O principal foco é o desenvolvimento de métodos
                  analíticos baseados na separação de amostras complexas por
                  cromatografia gasosa (GC), cromatografia líquida de alta
                  eficiência (HPLC) e eletroforese capilar (CE), além do
                  desenvolvimento de métodos de preparo de amostras voltados às
                  técnicas de separação, e desenvolvimento de estratégias de
                  detecção acopladas aos sistemas de separação. Na equipe do
                  INCTBio há também pesquisas direcionadas para o
                  desenvolvimento de métodos de separação multidimensionais,
                  novas fases estacionárias, microssistemas de análise
                  (Lab-on-a-chip), dispositivos em papel (paper-based devices) e
                  diversas aplicações em genômica, proteômica, metabolômica,
                  lipidômica, estresse oxidativo, pesquisa de biomarcadores de
                  doenças, dentre outras.
                </p>
                <img
                  id="imagemlegal"
                  class="mx-auto col-12 mt-5"
                  src="assets/img/about/img1.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
