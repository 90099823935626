import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

import Thumb17082019 from "./news-thumbs/Thumb17082019";
import Thumb23082019 from "./news-thumbs/Thumb23082019";
import Thumb27062019 from "./news-thumbs/Thumb27062019";
import Thumb20112020 from "./news-thumbs/Thumb20112020";
import Thumb201120202 from "./news-thumbs/Thumb201120202";

export default function News() {
  return (
    <>
      <Header />
      <section id="team" class="section-padding text-center">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="section-title-header text-center">
                <h1 class="section-title wow fadeInUp" data-wow-delay="0.2s">
                  Notícias e Artigos
                </h1>
              </div>
            </div>
            <Thumb20112020 />
            <Thumb201120202 />
            <Thumb27062019 />
            <Thumb17082019 />
            <Thumb23082019 />
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
