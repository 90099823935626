import React from "react";
import { Link } from 'react-router-dom'

export default function Header() {
  return (
    <>
      <header id="header-wrap">
        <nav class="navbar navbar-expand-lg fixed-top scrolling-navbar">
          <div class="container">
            <div class="navbar-header">
              <button
                class="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#main-navbar"
                aria-controls="main-navbar"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="navbar-toggler-icon"></span>
                <span class="icon-menu"></span>
                <span class="icon-menu"></span>
                <span class="icon-menu"></span>
              </button>
              <Link to="/" smooth class="navbar-brand">
                <img src="assets/img/logo.png" class="navbar-brand" alt="" />
              </Link>
            </div>
            <div class="collapse navbar-collapse" id="main-navbar">
              <ul class="navbar-nav mr-auto w-100 justify-content-end">
                <li class="nav-item active">
                  <Link to="/#header-wrap" smooth class="nav-link">Home</Link>
                </li>
                <li class="nav-item">
                  <Link to="/#event-slides" smooth class="nav-link">Sobre</Link>
                </li>
                <li class="nav-item">
                  <Link to="/#team" smooth class="nav-link">Pessoas</Link>
                </li>
                <li class="nav-item">
                  <Link to="/#areas" smooth class="nav-link">Pesquisa</Link>
                </li>

                <li class="nav-item">
                  <Link to="/#sponsors" smooth class="nav-link">Apoiadores</Link>
                </li>
              </ul>
            </div>
          </div>

          <ul class="mobile-menu">
            <li>
              <a class="page-scrool" href="#header-wrap">
                Home
              </a>
            </li>
            <li>
              <a class="page-scrool" href="#about">
                Sobre
              </a>
            </li>
            <li>
              <a class="page-scroll" href="#team">
                Pessoas
              </a>
            </li>
            <li>
              <a class="page-scroll" href="#areas">
                Pesquisa
              </a>
            </li>
            <li>
              <a class="page-scroll" href="#sponsors">
                Apoiadores
              </a>
            </li>
          </ul>
        </nav>
      </header>
    </>
  );
}
