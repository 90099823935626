import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

export default function Biosensors() {
  return (
    <>
      <Header />
      <section id="event-slides-area" class="section-padding">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="section-title-header text-center">
                <h1
                  id="area"
                  class="section-title wow fadeInUp"
                  data-wow-delay="0.2s"
                >
                  SOBRE A ÁREA DE BIOSSENSORES
                </h1>
              </div>
            </div>
            <img
              id="imagemlegal"
              class="mx-auto"
              src="assets/img/about/img3.jpg"
              alt=""
            />
          </div>
          <div class="wow" data-wow-delay="0.3s">
            <p class="normaltext text-justify col-12">
              Principais Tópicos de Pesquisa: Pesquisadores do Instituto
              Nacional de Bioanalítica (INCTBio) na área de Biossensores vêm
              trabalhando na síntese e caracterização de materiais
              nanoestruturados e/ou poliméricos, assim como nas suas respectivas
              funcionalizações através da imobilização de moléculas biológicas
              ou sistemas biomiméticos na superfície do eletrodo, para
              determinação de vários analitos de interesse clínico, ambiental e
              farmacológico. A descrição e o controle destes sistemas levam a
              uma nova geração de sistemas integrados, que combinam as
              propriedades únicas das nanoestruturas com a capacidade de
              reconhecimento molecular das moléculas biológicas.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
