import React from "react";
import { Link } from "react-router-dom";

export default function Thumb23082020() {
  return (
    <>
      <div class="col-lg-4 col-md-6 col-xs-12">
        <div class="blog-item">
          <div class="blog-image">
            <div class="text-center">
              <img
                class="img-fluid"
                src="assets/img/blog/webinarios.jpg"
                alt=""
              />
            </div>
          </div>
          <div class="descr">
            <div class="tag">Noticia</div>
            <h3 class="title">
              <Link to='/23082020'>
                INCTBio inicia a série “Webinários do INCTBio”
            </Link>
            </h3>
            <div class="meta-tags">
              <span class="date">Novembro 20, 2020</span>
              <span class="comments">
                | <a href="#"> José A. Fracassi da Silva.</a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
