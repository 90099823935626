import React from "react";

export default function SectionAbout() {
  return (
    <>
      <section id="event-slides" class="section-padding">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="section-title-header text-center">
                <h1 class="section-title wow fadeInUp" data-wow-delay="0.2s">
                  SOBRE O INSTITUTO
                </h1>
                <p class="wow fadeInDown" data-wow-delay="0.2s">
                  Conheça um pouco mais sobre o INCTBio
                </p>
              </div>
            </div>
            <div
              class="col-md-6 col-lg-6 col-xs-12 wow fadeInRight"
              data-wow-delay="0.3s"
            >
              <div class="video">
                <img
                  class="img-fluid"
                  src="assets/img/about/about.jpg"
                  alt=""
                />
              </div>
            </div>
            <div
              class="col-md-6 col-lg-6 col-xs-10 wow fadeInLeft"
              data-wow-delay="0.3s"
            >
              <p class="intro-desc">
                INCTBio foi inicialmente criado em 2008, com o principal
                objetivo de consolidar a Química Bioanalítica no país.
                Atualmente, consiste em uma rede envolvendo 43 grupos de
                pesquisas de 18 instituições de ensino superior brasileiras,
                sendo financiado pelo Programa Institutos Nacionais de Ciência e
                Tecnologia do Ministério da Ciência e Tecnologia sob coordenação
                do Conselho Nacional de Pesquisa e Desenvolvimento Científico.
              </p>
              <p class="intro-desc">
                {" "}
                O grupo é constituído por especialistas das principais áreas da
                Química Analítica potencialmente aplicável para amostras
                biológicas incluindo pesquisadores com larga experiência
                internacional e comprovada capacidade produtiva, além de jovens
                pesquisadores em grupos ainda não consolidados e de instituições
                que ainda apresentam pouca tradição em pesquisa. Nesta segunda
                edição do INCTBio, somamos forças com pesquisadores das áreas
                Biológicas e Ciências Médicas, ampliando a área de conhecimento
                e abrangência do nosso Instituto.
              </p>
              <p class="intro-desc">
                O principal objetivo deste Instituto, é demonstrar a importância
                da Química Bioanalítica no Brasil, ajudando no avanço de
                melhorias e desenvolvimento de novas técnicas e produtos para a
                sociedade. Para isso, congrega pesquisadores com formação e
                atuação nas áreas de Química Analítica, Biológicas e Medicina
                para, de forma coordenada colocar o país dentre os líderes no
                desenvolvimento de ferramentas analíticas necessárias para
                diagnósticos clínicos, análises bioquímicas e farmacológicas.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
