import React from "react";
import { Link } from "react-router-dom";

export default function Thumb201120202() {
  return (
    <>
      <div class="col-lg-4 col-md-6 col-xs-12">
        <div class="blog-item">
          <div class="blog-image">
            <div class="text-center">
              <img
                class="img-fluid"
                src="assets/img/blog/copaiba-arvore.jpg"
                alt=""
              />
            </div>
          </div>
          <div class="descr">
            <div class="tag">Noticia</div>
            <h3 class="title">
                <Link to='/201120202'>
                Determinação Rápida de Pureza de Óleos de Copaíba por
                Espectrômetro Portátil NIR e PLS R
                </Link>
            </h3>
            <div class="meta-tags">
              <span class="date">Novembro 20, 2020</span>
              <span class="comments">
                | <a href="#"> José A. Fracassi da Silva.</a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
