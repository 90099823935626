import React from "react";

export default function SectionStats() {
  return (
    <>
      <section class="counter-section section-padding">
        <div class="container">
          <div class="row">
            <div class="col-md-6 col-lg-3 col-xs-12 work-counter-widget text-center">
              <div class="counter wow fadeInRight" data-wow-delay="0.3s">
                <div class="icon">
                  <i class="lni lni-network"></i>
                </div>
                <p>45 pesquisadores</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-3 col-xs-12 work-counter-widget text-center">
              <div class="counter wow fadeInRight" data-wow-delay="0.6s">
                <div class="icon">
                  <i class="lni lni-stats-up"></i>
                </div>
                <p>+500 publicações</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-3 col-xs-12 work-counter-widget text-center">
              <div class="counter wow fadeInRight" data-wow-delay="0.9s">
                <div class="icon">
                  <i class="lni lni-invention"></i>
                </div>
                <p>Diversas patentes</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-3 col-xs-12 work-counter-widget text-center">
              <div class="counter wow fadeInRight" data-wow-delay="1.2s">
                <div class="icon">
                  <i class="lni lni-magnifier"></i>
                </div>
                <p>Referência em pesquisa</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
