import React from "react";

export default function Footer() {
  return (
    <>
      <footer class="footer-area section-padding">
        <div class="container">
          <div class="row">
            <div
              class="col-md-6 col-lg-3 col-sm-6 col-xs-12 wow fadeInUp"
              data-wow-delay="0.2s"
            >
              <h3>
                <img src="assets/img/logo.png" alt="" />
              </h3>
            </div>
            <div
              class="col-md-6 col-lg-3 col-sm-6 col-xs-12 wow fadeInUp"
              data-wow-delay="0.4s"
            >
              <h3>LINKS RÁPIDOS</h3>
              <ul>
                <li>
                  <a href="#event-slides">Sobre</a>
                </li>
                <li>
                  <a href="#team">Pesquisadores</a>
                </li>
                <li>
                  <a href="#blog">Novidades</a>
                </li>
              </ul>
            </div>
            <div
              class="col-md-6 col-lg-3 col-sm-6 col-xs-12 wow fadeInUp"
              data-wow-delay="0.8s"
            >
              {/* <h3>SE INSCREVA</h3>
              <div class="widget">
                <div class="newsletter-wrapper">
                  <form
                    method="post"
                    id="subscribe-form"
                    name="subscribe-form"
                    class="validate"
                  >
                    <div class="form-group is-empty">
                      <input
                        type="email"
                        value=""
                        name="Email"
                        class="form-control"
                        id="EMAIL"
                        placeholder="Your email"
                        required=""
                      />
                      <button
                        type="submit"
                        name="subscribe"
                        id="subscribes"
                        class="btn btn-common sub-btn"
                      >
                        <i class="lni lni-pointer"></i>
                      </button>
                      <div class="clearfix"></div>
                    </div>
                  </form>
                </div>
              </div> */}

              <div class="widget">
                <h5 class="widget-title">SIGA O INSTITUTO NAS REDES SOCIAIS</h5>
                <ul class="footer-social">
                  <li>
                    <a class="facebook" href="https://www.facebook.com/inctbio">
                      <i class="lni lni-facebook-filled"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
