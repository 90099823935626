import React from "react";
import { Link } from "react-router-dom";

export default function SectionResearchers() {
  return (
    <>
      <section id="team" class="section-padding text-center">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="section-title-header text-center">
                <h1 class="section-title wow fadeInUp" data-wow-delay="0.2s">
                  Pesquisadores
                </h1>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-4">
              <div class="team-item wow fadeInUp" data-wow-delay="0.2s">
                <div class="team-img">
                  <img
                    class="img-fluid"
                    src="assets/img/team/team-01.jpg"
                    alt=""
                  />
                  <div class="team-overlay">
                    <div class="overlay-social-icon text-center">
                      <ul class="social-icons">
                        <li>
                          <a target="_blank" href="http://lattes.cnpq.br/1050516524103988">
                            <i class="lni lni-world" aria-hidden="true"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="info-text">
                  <h3>
                    <a target="_blank" href="http://lattes.cnpq.br/1050516524103988"> José A. Fracassi</a>
                  </h3>
                </div>
              </div>
            </div>


            <div class="col-sm-6 col-md-6 col-lg-4">
              <div class="team-item wow fadeInUp" data-wow-delay="0.4s">
                <div class="team-img">
                  <img
                    class="img-fluid"
                    src="assets/img/team/team-02.jpg"
                    alt=""
                  />
                  <div class="team-overlay">
                    <div class="overlay-social-icon text-center">
                      <ul class="social-icons">
                        <li>
                          <a target="_blank" href="http://lattes.cnpq.br/7572366766177648">
                            <i class="lni lni-world" aria-hidden="true"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="info-text">
                  <h3>
                    <a target="_blank" href="http://lattes.cnpq.br/7572366766177648"> Lauro T. Kubota</a>
                  </h3>
                </div>
              </div>
            </div>

            <div class="col-sm-6 col-md-6 col-lg-4">
              <div class="team-item wow fadeInUp" data-wow-delay="0.6s">
                <div class="team-img">
                  <img
                    class="img-fluid"
                    src="assets/img/team/team-03.jpg"
                    alt=""
                  />
                  <div class="team-overlay">
                    <div class="overlay-social-icon text-center">
                      <ul class="social-icons">
                        <li>
                          <a target="_blank" href="http://lattes.cnpq.br/8023294344647435">
                            <i class="lni lni-world" aria-hidden="true"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="info-text">
                  <h3>
                    <a target="_blank" href="http://lattes.cnpq.br/8023294344647435"> Marco A. Z. Arruda</a>
                  </h3>
                </div>
              </div>
            </div>

            <div class="col-sm-6 col-md-6 col-lg-4">
              <div class="team-item wow fadeInUp" data-wow-delay="0.8s">
                <div class="team-img">
                  <img
                    class="img-fluid"
                    src="assets/img/team/team-04.jpg"
                    alt=""
                  />
                  <div class="team-overlay">
                    <div class="overlay-social-icon text-center">
                      <ul class="social-icons">
                        <li>
                          <a target="_blank" href="http://lattes.cnpq.br/9470670431610758">
                            <i class="lni lni-world" aria-hidden="true"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="info-text">
                  <h3>
                    <a target="_blank" href="http://lattes.cnpq.br/9470670431610758"> Dosil P. Jesus</a>
                  </h3>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-4">
              <div class="team-item wow fadeInUp" data-wow-delay="1s">
                <div class="team-img">
                  <img
                    class="img-fluid"
                    src="assets/img/team/team-05.jpg"
                    alt=""
                  />
                  <div class="team-overlay">
                    <div class="overlay-social-icon text-center">
                      <ul class="social-icons">
                        <li>
                          <a target="_blank" href="http://lattes.cnpq.br/6894949104612197">
                            <i class="lni lni-world" aria-hidden="true"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="info-text">
                  <h3>
                    <a target="_blank" href="http://lattes.cnpq.br/6894949104612197"> Carla B. G. Bottoli</a>
                  </h3>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-4">
              <div class="team-item wow fadeInUp" data-wow-delay="1.2s">
                <div class="team-img">
                  <img
                    class="img-fluid"
                    src="assets/img/team/team-06.jpg"
                    alt=""
                  />
                  <div class="team-overlay">
                    <div class="overlay-social-icon text-center">
                      <ul class="social-icons">
                        <li>
                          <a target="_blank" href="http://lattes.cnpq.br/9616664867188004">
                            <i class="lni lni-world" aria-hidden="true"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="info-text">
                  <h3>
                    <a target="_blank" href="http://lattes.cnpq.br/9616664867188004"> Alessandra Sussulini</a>
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <Link to="/pesquisadores" className="btn btn-common fadeInUp">
            Conheça todos
          </Link>
        </div>
      </section>
    </>
  );
}
