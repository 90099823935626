import React from "react";
import { Link } from 'react-router-dom'

export default function Thumb27062019() {
  return (
    <>
      <div class="col-lg-4 col-md-6 col-xs-12">
        <div class="blog-item">
          <div class="blog-image">
            <Link to='/post/27-06-2019'>
              <img class="img-fluid" src="assets/img/blog/img-3.jpg" alt="" />
            </Link>
          </div>
          <div class="descr">
            <div class="tag">Eventos</div>
            <h3 class="title">
              <a target="_blank" href="https://www.inova.unicamp.br/premioinventores/2019/07/aos-nossos-inventores-uma-homenagem">
                INCTBio marcando presença no Prêmio Inventores 2019 UNICAMP, na
                categoria “Patente Concedida”.
              </a>
            </h3>
            <div class="meta-tags">
              <span class="date">Junho 27, 2019</span>
              <span class="comments">
                | <a href="#"> José A. Fracassi da Silva.</a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
