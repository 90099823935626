import React from 'react'
import SectionHeader from '../../components/SectionHeader'
import SectionAbout from '../../components/SectionAbout'
import SectionNews from '../../components/SectionNews'
import SectionResearchers from '../../components/SectionResearchers'
import SectionSponsors from '../../components/SectionSponsors'
import SectionStats from '../../components/SectionStats'
import Footer from '../../components/Footer'
import SectionAreas from '../../components/SectionAreas'

export default function Home() {
    return (
        <>
          <SectionHeader />
          <SectionAbout />
          <SectionAreas />
          <SectionNews />
          <SectionStats />
          <SectionResearchers />
          <SectionSponsors />
          <Footer />
        </>
    )
}
