import React from "react";

// import { Link } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';


export default function Header() {
  return (
    <>
      <header id="header-wrap">
        <nav class="navbar navbar-expand-lg fixed-top scrolling-navbar">
          <div class="container">
            <div class="navbar-header">
              <button
                class="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#main-navbar"
                aria-controls="main-navbar"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="navbar-toggler-icon"></span>
                <span class="icon-menu"></span>
                <span class="icon-menu"></span>
                <span class="icon-menu"></span>
              </button>
              <Link to="/" smooth class="navbar-brand">
                <img src="assets/img/logo.png" class="navbar-brand" alt="" />
              </Link>
            </div>
            <div class="collapse navbar-collapse" id="main-navbar">
              <ul class="navbar-nav mr-auto w-100 justify-content-end">
                <li class="nav-item active">
                  <Link to="/#header-wrap" smooth class="nav-link">Home</Link>
                </li>
                <li class="nav-item">
                  <Link to="/#event-slides" smooth class="nav-link">Sobre</Link>
                </li>
                <li class="nav-item">
                  <Link to="/#team" smooth class="nav-link">Pessoas</Link>
                </li>
                <li class="nav-item">
                  <Link to="/#areas" smooth class="nav-link">Pesquisa</Link>
                </li>

                <li class="nav-item">
                  <Link to="/#sponsors" smooth class="nav-link">Apoiadores</Link>
                </li>
              </ul>
            </div>
          </div>

          <ul class="mobile-menu">
            <li>
              <a class="page-scrool" href="#header-wrap">
                Home
              </a>
            </li>
            <li>
              <a class="page-scrool" href="#about">
                Sobre
              </a>
            </li>
            <li>
              <a class="page-scroll" href="#team">
                Pessoas
              </a>
            </li>
            <li>
              <a class="page-scroll" href="#areas">
                Pesquisa
              </a>
            </li>
            <li>
              <a class="page-scroll" href="#sponsors">
                Apoiadores
              </a>
            </li>
          </ul>
        </nav>

        <div id="main-slide" class="carousel slide" data-ride="carousel">
          <ol class="carousel-indicators">
            <li data-target="#main-slide" data-slide-to="0" class="active"></li>
            <li data-target="#main-slide" data-slide-to="1"></li>
            <li data-target="#main-slide" data-slide-to="2"></li>
          </ol>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img
                class="d-block w-100"
                src="assets/img/slider/slide1.jpg"
                alt="First slide"
              />
              <div class="carousel-caption d-md-block">
                <h1 class="wow fadeInDown heading" data-wow-delay=".4s">
                  INCTbio
                </h1>
                <p class="fadeInUp wow" data-wow-delay=".6s">
                  {" "}
                  Instituto Nacional de Ciência e Tecnologia de Bioanalítica
                </p>

                <a
                  href="#blog"
                  class="fadeInLeft wow btn btn-common btn-lg"
                  data-wow-delay=".6s"
                >
                  Publicações
                </a>
                <a
                  href="#event-slides"
                  class="fadeInRight wow btn btn-border btn-lg"
                  data-wow-delay=".6s"
                >
                  Conheca o instituto
                </a>
              </div>
            </div>
            <div class="carousel-item">
              <img
                class="d-block w-100"
                src="assets/img/slider/slide2.jpg"
                alt="Second slide"
              />
              <div class="carousel-caption d-md-block">
                <h1 class="wow fadeInDown heading" data-wow-delay=".7s">
                  44 Grupos de Pesquisa
                </h1>
                <a
                  href="#"
                  class="fadeInUp wow btn btn-common btn-lg"
                  data-wow-delay=".8s"
                >
                  Conheça os grupos
                </a>
              </div>
            </div>
            <div class="carousel-item">
              <img
                class="d-block w-100"
                src="assets/img/slider/slide3.jpg"
                alt="Third slide"
              />
              <div class="carousel-caption d-md-block">
                <h1 class="wow fadeInDown heading" data-wow-delay=".6s">
                  Mais de 500 artigos publicados
                </h1>
                <a
                  href="#"
                  class="fadeInUp wow btn btn-common btn-lg"
                  data-wow-delay=".8s"
                >
                  Explore as publicações
                </a>
              </div>
            </div>
          </div>
          <a
            class="carousel-control-prev"
            href="#main-slide"
            role="button"
            data-slide="prev"
          >
            <span class="carousel-control" aria-hidden="true">
              <i class="lni lni-chevron-left"></i>
            </span>
            <span class="sr-only">Anterior</span>
          </a>
          <a
            class="carousel-control-next"
            href="#main-slide"
            role="button"
            data-slide="next"
          >
            <span class="carousel-control" aria-hidden="true">
              <i class="lni lni-chevron-right"></i>
            </span>
            <span class="sr-only">Próximo</span>
          </a>
        </div>
      </header>
    </>
  );
}
