let researchersList = [
    { name: " Lauro Tatsuo Kubota", imageURL: "assets/img/team/pesquisadores/01.jpg", profileURL: 'http://lattes.cnpq.br/7572366766177648'},
    { name: " Marília O. F. Goulart", imageURL: "assets/img/team/pesquisadores/02.jpg", profileURL: 'http://lattes.cnpq.br/5271094336884473'},
    { name: " Auro Atsushi Tanaka", imageURL: "assets/img/team/pesquisadores/03.jpg", profileURL: 'http://lattes.cnpq.br/1460765270568999'},
    { name: " Emanuel Carrilho", imageURL: "assets/img/team/pesquisadores/04.jpg", profileURL: 'http://lattes.cnpq.br/9157872297410868'},
    { name: " Erico M. M. Flores", imageURL: "assets/img/team/pesquisadores/05.jpg", profileURL: 'http://lattes.cnpq.br/7167629055579212'},
    { name: " Lucio Angnes", imageURL: "assets/img/team/pesquisadores/06.jpg", profileURL: 'http://lattes.cnpq.br/1936337806232049'},
    { name: " Marco Aurélio Zezzi Arruda", imageURL: "assets/img/team/pesquisadores/07.jpg", profileURL: 'http://lattes.cnpq.br/8023294344647435'},
    { name: " Adriana Vitorino Rossi", imageURL: "assets/img/team/pesquisadores/08.jpg", profileURL: 'http://lattes.cnpq.br/4810363221024239'},
    { name: " Alfredo L. M. L. Mateus", imageURL: "assets/img/team/pesquisadores/09.jpg", profileURL: 'http://lattes.cnpq.br/3957196350399649'},
    { name: " Alessandra Sussulini", imageURL: "assets/img/team/pesquisadores/10.jpg", profileURL: 'http://lattes.cnpq.br/9616664867188004'},
    { name: " Ana Valéria C. Simionato", imageURL: "assets/img/team/pesquisadores/11.jpg", profileURL: 'http://lattes.cnpq.br/7623428599537044'},
    { name: " Carla B. G. Bottoli", imageURL: "assets/img/team/pesquisadores/12.jpg", profileURL: 'http://lattes.cnpq.br/6894949104612197'},
    { name: " César R. T. Tarley", imageURL: "assets/img/team/pesquisadores/13.jpg", profileURL: 'http://lattes.cnpq.br/9803419937796467'},
    { name: " Dosil P. Jesus", imageURL: "assets/img/team/pesquisadores/14.jpg", profileURL: 'http://lattes.cnpq.br/9470670431610758'},
    { name: " Eduardo M. Richter", imageURL: "assets/img/team/pesquisadores/15.jpg", profileURL: 'http://lattes.cnpq.br/0310068697989712'},
    { name: " Fabio Augusto", imageURL: "assets/img/team/pesquisadores/16.jpg", profileURL: 'http://lattes.cnpq.br/8190168751489630'},
    { name: " Fernanda F. Anibal", imageURL: "assets/img/team/pesquisadores/17.jpg", profileURL: 'http://lattes.cnpq.br/4918261968772806'},
    { name: " Fernanda Ortis", imageURL: "assets/img/team/pesquisadores/18.jpg", profileURL: 'http://lattes.cnpq.br/4300428169390988'},
    { name: " Fernando Barbosa Jr.", imageURL: "assets/img/team/pesquisadores/19.jpg", profileURL: 'http://lattes.cnpq.br/5072630975779407'},
    { name: " Fernando Abdulkader", imageURL: "assets/img/team/pesquisadores/20.jpg", profileURL: 'http://lattes.cnpq.br/9107964942261446'},
    { name: " Flavio Santos Damos", imageURL: "assets/img/team/pesquisadores/21.jpg", profileURL: 'http://lattes.cnpq.br/0915687070195770'},
    { name: " Jacqueline A. Da Silva", imageURL: "assets/img/team/pesquisadores/22.jpg", profileURL: 'http://lattes.cnpq.br/4712538462433785'},
    { name: " Jez W. B. Braga", imageURL: "assets/img/team/pesquisadores/23.jpg", profileURL: 'http://lattes.cnpq.br/1505814835374901'},
    { name: " José A. Fracassi da Silva", imageURL: "assets/img/team/pesquisadores/24.jpg", profileURL: 'http://lattes.cnpq.br/1050516524103988'},
    { name: " Ljubica Tasic", imageURL: "assets/img/team/pesquisadores/25.jpg", profileURL: 'http://lattes.cnpq.br/4164247517680621'},
    { name: " Luiz H. Dall’Antônia", imageURL: "assets/img/team/pesquisadores/26.jpg", profileURL: 'http://lattes.cnpq.br/0622474265250573'},
    { name: " Marcelo M. Sena", imageURL: "assets/img/team/pesquisadores/27.jpg", profileURL: 'http://lattes.cnpq.br/7050638697696950'},
    { name: " Marcio E. V. Miyata", imageURL: "assets/img/team/pesquisadores/28.jpg", profileURL: 'http://lattes.cnpq.br/3722484930445197'},
    { name: " Marco F. Ferrão", imageURL: "assets/img/team/pesquisadores/29.jpg", profileURL: 'http://lattes.cnpq.br/7552747227876113'},
    { name: " Marcone A. L. Oliveira", imageURL: "assets/img/team/pesquisadores/30.jpg", profileURL: 'http://lattes.cnpq.br/9646858182980011'},
    { name: " Márcia C. Breitkreitz", imageURL: "assets/img/team/pesquisadores/31.jpg", profileURL: 'http://lattes.cnpq.br/7764198166588453'},
    { name: " Ricardo E. Santelli", imageURL: "assets/img/team/pesquisadores/32.jpg", profileURL: 'http://lattes.cnpq.br/1694260923221276'},
    { name: " Rita C. S. Luz", imageURL: "assets/img/team/pesquisadores/33.jpg", profileURL: 'http://lattes.cnpq.br/1282444701404832'},
    { name: " Rodrigo A. A. Muñoz", imageURL: "assets/img/team/pesquisadores/34.jpg", profileURL: 'http://lattes.cnpq.br/0884149595277368'},
    { name: " Rosa A. F. Dutra", imageURL: "assets/img/team/pesquisadores/36.jpg", profileURL: 'http://lattes.cnpq.br/3335497739195055'},
    { name: " Rose M. Z. G. Naal", imageURL: "assets/img/team/pesquisadores/37.jpg", profileURL: 'http://lattes.cnpq.br/1722858999054397'},
    { name: " Sergio A. S. Machado", imageURL: "assets/img/team/pesquisadores/38.jpg", profileURL: 'http://lattes.cnpq.br/9906894972452817'},
    { name: " Thiago R. L. C. Paixão", imageURL: "assets/img/team/pesquisadores/39.png", profileURL: 'http://lattes.cnpq.br/8309224853345314'},
    { name: " Wendel A. Alves", imageURL: "assets/img/team/pesquisadores/40.jpg", profileURL: 'http://lattes.cnpq.br/7275057980870698'},
    { name: " Wendell K. T. Coltro", imageURL: "assets/img/team/pesquisadores/41.jpg", profileURL: 'http://lattes.cnpq.br/8302650734477213'},
    { name: " Zeki Naal", imageURL: "assets/img/team/pesquisadores/42.jpg", profileURL: 'http://lattes.cnpq.br/5524300183008573'},
    { name: " Fernando Henrique Cincotto", imageURL: "assets/img/team/pesquisadores/fernando-cincotto.jpg", profileURL: "http://lattes.cnpq.br/8269290654909102"},
    { name: " Leandro Wang Hantao", imageURL: "assets/img/team/pesquisadores/leandro-wang.jpg", profileURL: "http://lattes.cnpq.br/6101462103157416"},
    { name: " Márcia Foster Mesko", imageURL: "assets/img/team/pesquisadores/marcia-mesko.jpg", profileURL: "http://lattes.cnpq.br/2461255030745891"},
];

// ordena por ordem alfabética os nomes
researchersList = researchersList.sort(function(a, b) {
    var textA = a.name.toUpperCase();
    var textB = b.name.toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
});

export { researchersList } 



