import React from "react";


export default function ResearchItem({name, imageURL, profileURL}) {
  return (
    <>
      <div class="col-sm-6 col-md-6 col-lg-4">
        <div class="team-item wow fadeInUp" data-wow-delay="0.2s">
          <div class="team-img">
            <img
              class="img-fluid"
              src={imageURL}
              alt=""
            />
            <div class="team-overlay">
              <div class="overlay-social-icon text-center">
                <ul class="social-icons">
                  <li>
                    <a href={profileURL} alt="" target="_blank">
                      <i class="lni lni-world" aria-hidden="true"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="info-text">
            <h3>
              <a href={profileURL} target="_blank">{name}</a>
            </h3>
          </div>
        </div>
      </div>
    </>
  );
}
