import React from "react";

export default function Thumb23082019() {
  return (
    <>
      <div class="col-lg-4 col-md-6 col-xs-12">
        <div class="blog-item">
          <div class="blog-image">
            <a href="#">
              <img class="img-fluid" src="assets/img/blog/img-5.jpeg" alt="" />
            </a>
          </div>
          <div class="descr">
            <div class="tag">Eventos</div>
            <h3 class="title">
              <a href="#">
                A 7ª Escola de Bioanalítica será realizada em 2019 entre 04 e 06
                de dezembro, na Universidade Federal de Uberlândia, sob a
                coordenação local dos membros do INCTBio Profs. Drs. Eduardo M.
                Richter e Rodrigo A. Abarza-Muñoz. Reserve a data na agenda!
              </a>
            </h3>
            <div class="meta-tags">
              <span class="date">Agosto 23, 2019</span>
              <span class="comments">
                | <a href="#"> José A. Fracassi da Silva.</a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
