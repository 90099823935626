import React from "react";

export default function SectionSponsors() {
  return (
    <>
      <section id="sponsors" class="section-padding">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="section-title-header text-center">
                <h1
                  id="apoiadores"
                  class="section-title wow fadeInUp"
                  data-wow-delay="0.2s"
                >
                  Apoiadores
                </h1>
                <p
                  id="instituiçõesquesuportam"
                  class="wow fadeInDown"
                  data-wow-delay="0.2s"
                >
                  Instituições que suportam o futuro da bioanalítica
                </p>
              </div>
            </div>
          </div>
          <div
            class="row mb-30 text-center wow fadeInDown"
            data-wow-delay="0.3s"
          >
            <div class="col-md-3 col-sm-3 col-xs-12">
              <div class="spnsors-logo">
                <a href="http://cnpq.br" target="_blank">
                  <img
                    class="img-fluid"
                    src="assets/img/sponsors/logo-01.jpg"
                    alt=""
                  />
                </a>
              </div>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-12">
              <div class="spnsors-logo">
                <a href="https://fapesp.br" target="_blank">
                  <img
                    class="img-fluid"
                    src="assets/img/sponsors/logo-02.jpg"
                    alt=""
                  />
                </a>
              </div>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-12">
              <div class="spnsors-logo">
                <a href="https://www.metrohm.com" target="_blank">
                  <img
                    class="img-fluid"
                    src="assets/img/sponsors/logo-03.jpg"
                    alt=""
                  />
                </a>
              </div>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-12">
              <div class="spnsors-logo">
                <a href="http://www.mctic.gov.br/portal" target="_blank">
                  <img
                    class="img-fluid"
                    src="assets/img/sponsors/logo-04.jpg"
                    alt=""
                  />
                </a>
              </div>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-12">
              <div class="spnsors-logo">
                <a href="https://www.capes.gov.br" target="_blank">
                  <img
                    class="img-fluid"
                    src="assets/img/sponsors/logo-05.jpg"
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
