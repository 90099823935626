import React from "react";

export default function Thumb17082019() {
  return (
    <>
      <div class="col-lg-4 col-md-6 col-xs-12">
        <div class="blog-item">
          <div class="blog-image">
            <a target="_blank" href="http://www.abc.org.br/2019/08/14/simposio-e-diplomacao-dos-membros-afiliados-da-abc-regional-minas-gerais-centro-oeste/">
              <img class="img-fluid" src="assets/img/blog/img-4.jpg" alt="" />
            </a>
          </div>
          <div class="descr">
            <div class="tag">Eventos</div>
            <h3 class="title">
              <a target="_blank" href="http://www.abc.org.br/2019/08/14/simposio-e-diplomacao-dos-membros-afiliados-da-abc-regional-minas-gerais-centro-oeste/">
                O Prof. Dr. Wendell K. T. Coltro é o mais novo membro da
                Academia Brasileira de Ciências. Parabéns Prof. Wendell.
              </a>
            </h3>
            <div class="meta-tags">
              <span class="date">Agosto 17, 2019</span>
              <span class="comments">| José A. Fracassi da Silva.</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
