import React from "react";
import Footer from "../../../../components/Footer";
import Header from "../../../../components/Header";

import img1 from './img1.jpg'

export default function Page23082020() {
  return (
    <>
      <Header />
      <section id="event-slides-area" className="section-padding">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title-header text-center">
                <h1
                  id="area"
                  class="section-title wow fadeInUp"
                  data-wow-delay="0.2s"
                >
                  INCTBIO INICIA A SÉRIE “WEBINÁRIOS DO INCTBIO”
                </h1>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-6">
              <div className="row justify-content-center">
                <img
                  className="img-fluid mx-auto"
                  src={img1}

                  alt=""
                />
              </div>
            </div>
            <div className="col">
              <div className="wow" data-wow-delay="0.3s">
                <p className="normaltext text-justify col-12">
                  Mensalmente, o INCTBio apresenta um Webinário com renomados pesquisadores do Brasil e exterior. Em novembro de 2020 o Prof. Dr. Érico M. M. Flores da UFSM, apresentou a palestra “Estratégias para a determinação elementar: Desafios na interface entre a Química Analítica e as Ciências Biológicas”. Em dezembro será a vez do Prof. Wendell Coltro falar sobre “Desenvolvimento de sistemas analíticos descartáveis para aplicações bioanalíticas”. Em Janeiro de 2021 temos agendada a participação da Profa. Dra. Susan M. Lunte (The University of Kansas). Participem!
                </p>
              </div>
            </div>
          </div>

        </div>
      </section >
      <Footer />
    </>
  );
}
