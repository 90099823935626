import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

export default function Electroanalytics() {
  return (
    <>
      <Header />
      <section id="event-slides-area" class="section-padding">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="section-title-header text-center">
                <h1
                  id="area"
                  class="section-title wow fadeInUp"
                  data-wow-delay="0.2s"
                >
                  SOBRE A ÁREA DE ESPECTROANALÍTICA
                </h1>
              </div>
              <div class="wow" data-wow-delay="0.3s">
                <p class="normaltext text-justify col-12">
                  A espectroanalítica é uma área onde são desenvolvidos métodos
                  de análise baseados em medidas de espectros, que podem ser
                  gerados pelas diferentes formas de radiação eletromagnética
                  (chamada de espectroscopia) ou pelas cargas e massas de íons
                  gerados a partir de uma substancia inicialmente neutra
                  (chamada de espectrometria de massas).
                </p>
                <p class="normaltext text-justify col-12">
                  {" "}
                  Pesquisadores do Instituto Nacional de Bioanalítica (INCTBio)
                  na área de espectroanalítica vêm trabalhando com as técnicas
                  de espectrometria de massas, espectroscopia de ressonância
                  magnética nuclear (RMN), espectroscopia no infravermelho
                  próximo (NIR) e espectroscopia Raman.
                </p>
                <p class="normaltext text-justify col-12">
                  As matrizes de interesse são as mais diversas, devido à
                  possibilidade de obtenção de informações em nível molecular
                  com alta resolução, muitas vezes de forma não destrutiva e com
                  pouco ou nenhum pré-tratamento da amostra. Exemplos de
                  aplicação são: estudos com células, tecidos, fluidos
                  biológicos, fármacos, alimentos, produtos naturais etc. O
                  crescimento da área tem sido expressivo, verificando-se um
                  aumento muito grande de trabalhos, bem como o surgimento de
                  grupos de pesquisa trabalhando especificamente na área de
                  análises de amostras biológicas. Nesse caso destaca-se a
                  metabolomica, que envolve o estudo dos metabólitos presentes
                  em diversas amostras biológicas através de sua identificação e
                  quantificação. Neste contexto, estudos metabolômicos são
                  fundamentais para a identificação de biomarcadores para
                  diferentes distúrbios e doenças, como por exemplo, transtornos
                  psiquiátricos e câncer. Portanto, a descoberta de
                  biomarcadores dessas doenças torna-se relevante no sentido de
                  aprimorar o diagnóstico, bem como na avaliação do tratamento
                  aplicado aos pacientes.
                </p>
                <p class="normaltext text-justify col-12">
                  A grande quantidade e a complexidade dos dados gerados muitas
                  vezes tornam necessária a utilização de ferramentas
                  quimiométricas de reconhecimento de padrões ou calibração
                  multivariada.
                </p>
                <img
                  id="imagemlegal"
                  class="mx-auto mt-5"
                  src="assets/img/about/img2.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
