import React from "react";

export default function Thumb20112020() {
  return (
    <>
      <div class="col-lg-4 col-md-6 col-xs-12">
        <div class="blog-item">
          <div class="blog-image">
            <a target="_blank" href="https://journals.plos.org/plosbiology/article?id=10.1371%2Fjournal.pbio.3000918&fbclid=IwAR1LBmD2R_YUSfpLxQ3VcGtIed4QC5To9WA0ZO_LFAX4dAxkK3_21hhF7bU">
              <img class="img-fluid" src="assets/img/blog/pesquisadores-influentes.png" alt="" />
            </a>
          </div>
          <div class="descr">
            <div class="tag">Noticia</div>
            <h3 class="title">
              <a target="_blank" href="https://journals.plos.org/plosbiology/article?id=10.1371%2Fjournal.pbio.3000918&fbclid=IwAR1LBmD2R_YUSfpLxQ3VcGtIed4QC5To9WA0ZO_LFAX4dAxkK3_21hhF7bU">
                8 Pesquisadores do INCTBio estão na lista do ranking dos 2% de cientistas mais influentes no mundo no ano de 2019!
              </a>
            </h3>
            <div class="meta-tags">
              <span class="date">Novembro 20, 2020</span>
              <span class="comments">
                | <a href="#"> José A. Fracassi da Silva.</a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
